import { Alert, Button, Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useLocation, useSearchParam, useToggle } from "react-use";
import { AuthenticationStatus, AuthorizeRequest, AuthorizeWebResponse } from "../eGate-API";
import { linkEGate } from "../helpers/eGateApi";
import LoginStyler from "./Login.style";
import useApp from "../hooks/useApp";
import classNames from "classnames";
import LoginFooter from "./LoginFooter";
import PasswordInput from "./PasswordInput";
import logo from "../image/main-logo.svg";
import Entities from "../Entities";
import { getDefaultView } from "../helpers/utils";
import qs from "qs";
import { GoogleLogin } from "@react-oauth/google";
import AppleLogin from "./AppleLogin";
import useServerInfo from "../hooks/useServerInfo";
/**
 * Web Login Component
 */
function LoginIn() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { login } = useApp();

  const [form] = Form.useForm();

  //apple/google login status
  const redirectLoginStatus = useSearchParam("status");
  //apple/google login description
  const redirectLoginDescription = useSearchParam("description");

  const [loginError, setLoginError] = useState<AuthenticationStatus>();
  const [loginErrorDescription, setLoginErrorDescription] = useState<string>();
  const [loginLoading, toggleLoginLoading] = useToggle(false);

  const locationState = useLocation();

  const [prevUrl, setPrevUrl] = useState("");

  const from = locationState?.state?.usr;
  useEffect(() => {
    if (from) {
      setPrevUrl(from);
    }
  }, [from]);

  const entities = Object.values(Entities);

  function getRolesByRoute(view: string) {
    const viewParsed = view.replace("/app/", "").split("/")[0];
    return entities.find((i) => i.baseRoute == viewParsed)?.roleView;
  }

  /**
   * Can view page that the user was redirected from into the login page
   * @param view
   * @param authResponse
   */
  function canViewPage(view: string, authResponse: AuthorizeWebResponse) {
    if (!view) {
      return undefined;
    }
    const rolesByRoute = getRolesByRoute(view) ?? [];
    const hasAnyRoleLogin = (...roles: string[]) => roles.some((r) => authResponse?.roles?.includes(r));
    return hasAnyRoleLogin(...rolesByRoute);
  }

  /**
   * Get redirect after login
   * Determines the right page for the account
   * @param auth
   */
  function getRedirect(auth: AuthorizeWebResponse): string {
    const canView = canViewPage(prevUrl, auth);

    if (!canView) {
      return getDefaultView(auth) ?? "/app/dashboard";
    }
    if (prevUrl) {
      return prevUrl;
    }

    return getDefaultView(auth) ?? "/app/dashboard";
  }

  /**
   * Submit form callback
   * @param values
   */
  async function onFinish(values: AuthorizeRequest) {
    try {
      toggleLoginLoading();
      const url = linkEGate("authorize/login");
      const headers: Record<string, string> = {
        "Content-Type": "application/json",
      };

      const response = await fetch(url + "/?culture=" + i18n.language, {
        method: "POST",
        body: JSON.stringify(values),
        headers,
      });

      if (response.status === 401) {
        const error: AuthorizeWebResponse = await response.json();
        setLoginError(error.status);
        setLoginErrorDescription(error.description);
      } else if (response.status === 200) {
        const authorizeWebResponse: AuthorizeWebResponse = await response.json();
        void login(authorizeWebResponse);
        const redirect = getRedirect(authorizeWebResponse);

        navigate(redirect);
      }
      toggleLoginLoading(false);
    } catch (e) {
      console.error(e);
      setLoginError("invalid");
      setLoginErrorDescription(t("Unknown error") as string);

      toggleLoginLoading();
    }
  }

  function getLoginErrorMessage() {
    return loginErrorDescription;
  }

  const location = useLocation();
  const { data: serverInfo } = useServerInfo();
  const appleClientId = serverInfo?.appleClientId;
  if (location.search) {
    if (
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      }).status == "Success"
    ) {
      return <Navigate to={"/app/dashboard"} />;
    }
  }

  return (
    <LoginStyler>
      <div className="login-content">
        <div className="logo-wrapper">
          <img alt="eDigi logo" src={logo} width="128px" />
        </div>
        <Form form={form} onFinish={(values: any) => onFinish(values as AuthorizeRequest)}>
          <Form.Item
            className="isoInputWrapper"
            name={"username"}
            noStyle
            rules={[{ required: true, message: t("Please input your email!") as string }]}
            shouldUpdate
          >
            <input disabled={loginLoading} placeholder={t("E-Mail")} />
          </Form.Item>
          <Form.Item
            className="isoInputWrapper"
            name={"password"}
            noStyle
            rules={[
              {
                required: true,
                message: t("Please input your password!") as string,
              },
            ]}
            shouldUpdate
          >
            <PasswordInput
              autocomplete={"current-password"}
              disabled={loginLoading}
              id="password"
              placeholder={t("Password")}
            />
          </Form.Item>
          <Form.Item className="isoInputWrapper" noStyle shouldUpdate>
            <Button
              className={classNames("login-button", {})}
              disabled={loginLoading}
              htmlType="submit"
              id="btnLogin"
              loading={loginLoading}
              type="primary"
            >
              {!loginLoading && t("Login")}
            </Button>
          </Form.Item>
          <GoogleLogin
            login_uri={linkEGate("authorize/google", null, false, "v2")}
            shape={"pill"}
            size={"medium"}
            text={"signin_with"}
            theme={"outline"}
            ux_mode={"redirect"}
            onSuccess={() => {}}
          />
          {appleClientId ? <AppleLogin clientId={appleClientId} /> : null}
          {loginError && <Alert message={getLoginErrorMessage()} type={"error"} />}

          {redirectLoginStatus && redirectLoginStatus != "Success" && (
            <Alert message={redirectLoginDescription ?? t("Login failed")} type={"error"} />
          )}

          <Link to="/reset-password">{t("Forgot password?")}</Link>
        </Form>
      </div>
      <LoginFooter />
    </LoginStyler>
  );
}

export default LoginIn;
