import { ROLE } from "../Auth/useRole";
import { EntityConfig, entityFactory } from "../hooks/useEntity";
import dayjs from "dayjs";

const conf: EntityConfig = {
  ...entityFactory("profile"),
  endpoint: "users/profile",
  roleView: [ROLE.APP_USER],
  roleAdmin: [ROLE.APP_USER],
  singularName: (t) => t("Profile"),
  name: (t) => t("Profile"),
  initialFilter: {
    from: dayjs().startOf("month").subtract(3, "months").format(),
    to: dayjs().endOf("month").format(),
  },
  syncFilterWithURL: true,
  noIdInUrl: true,
  dependencies: ["user"],
};

export default conf;
