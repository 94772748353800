import { Resource } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import useServerInfo from "../../hooks/useServerInfo";
import { useQueryClient } from "@tanstack/react-query";
import Entities from "../../Entities";

export default () => {
  const { i18n } = useTranslation();
  const o: Resource | undefined = i18n.options?.resources;
  const queryClient = useQueryClient();

  const { data } = useServerInfo();

  const languages = data?.languages;

  async function handleChangeLanguage(lang: string) {
    await i18n.changeLanguage(lang);
    document.documentElement.lang = lang;

    const promiseList = Object.values(Entities)
      .filter((i) => {
        return i.sendCulture;
      })
      .map((i) => {
        return queryClient
          .invalidateQueries({
            predicate: (query) => {
              return (query.queryKey[0] as string)?.indexOf(i.key) != -1;
            },
          })
          .then(() =>
            queryClient.invalidateQueries({
              predicate: (query) => {
                return (query.queryKey[0] as string)?.indexOf(i.keyDetail as string) != -1;
              },
            })
          );
      });

    await Promise.all(promiseList);
  }

  const languageLabel = languages?.find((i) => i.id === i18n.language);

  function renderItems() {
    return (
      data !== undefined &&
      o !== undefined &&
      Object.keys(o).map((lang: string) => {
        const language = languages?.find((i) => i.id === lang);

        return {
          key: lang,
          className: "hover-primary",
          label: (
            <div key={lang} data-cy={"language-switcher-language-" + lang}>
              {language?.nativeName} | {language?.name}
            </div>
          ),
          onClick: () => {
            handleChangeLanguage(lang);
          },
        };
      })
    );
  }

  return {
    key: "language",
    className: "language-switcher",
    label: (
      <div className={"icon-wrapper-circle language-label hover-primary"} data-cy={"language-switcher"}>
        {languageLabel?.nativeName}
      </div>
    ),
    children: renderItems(),
  };
};
