import { EntityConfig, entityFactory } from "../hooks/useEntity";
import { ROLE } from "../Auth/useRole";
import dayjs from "dayjs";
import { shortTimeFormat } from "../helpers/timeUtils";

export const conf: EntityConfig = {
  ...entityFactory("end-test"),
  endpoint: "end-test",
  privateUrl: true,
  roleView: [ROLE.ENDTEST_VIEW],
  roleAdmin: [ROLE.ENDTEST_RUN],
  initialFilter: { sortBy: "testStart", sortOrder: "descend" },
  singularName: (t) => t("End test"),
  name: (t) => t("End tests"),
  filterFormatter: ({ to, from, ...filter }: any) => {
    return {
      ...filter,
      testedTo: to ? dayjs(to).utc().format(shortTimeFormat) : undefined,
      testedFrom: from ? dayjs(from).utc().format(shortTimeFormat) : undefined,
    };
  },
  noDetail: true,
};

export default conf;
